import * as React from "react";
import { Link } from "gatsby";
import { Helmet } from "react-helmet";

// markup
const NotFoundPage = () => {
  return (
    <>
      <main className="relative" style={{ minHeight: "100vh" }}>
        <Helmet>
          <title>404 - Battle Of the Chat</title>
        </Helmet>
        <div
          className="flex flex-col items-center"
          style={{ paddingBottom: "252px" }}
        >
          <h1 className="my-10 text-4xl font-bold">404 - Page not found</h1>
          <p className="m-2 text-lg">
            The road ends here {":("}
            <br />
            <Link to="/" className="mt-5 text-underline text-blue-300">
              Go home
            </Link>
            .
          </p>
        </div>
      </main>
    </>
  );
};

export default NotFoundPage;
